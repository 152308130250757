<template>
  <div>
    <div class="link-not-available-page absolute text-center full-width">
      <div class="text absolute full-width full-height flex justify-center items-center ops">
        {{ $t('text.oops') }}
      </div>
      <div class="text text-25-400 absolute full-width full-height flex justify-center items-center">
        <div>{{ $t('text.linkNotAvailable') }}</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'LinkNotAvailablePage',
};
</script>

<style scoped lang="scss">
.link-not-available-page {
  height: calc(100% - 115px);

  .ops {
    font-size: 470px;
    font-weight: 700;
    color: $grey-6;
    line-height: 0;
  }

  .text {
    top: 0;
  }
}
</style>
